import React from "react";
import { Dialog, Classes } from "@blueprintjs/core";
import { FormProps, QuestionForm } from "./Form";
import { OrderDictionaryType, OrderDictionaryItemType } from "modules/orders-manage/models/order-dictionary";
import {
  ProjectSelect,
  renderProjectOption,
  ProjectSelectedOption,
  filterProjectItemPredicate,
} from "modules/common/components/form/ProjectSelect";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { texts } from "modules/common/texts";
import styles from "./Details.module.scss";
import { OrderMailsSchema } from "./validation";
import { observer } from "mobx-react";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import { questionFields } from "modules/orders-manage/models/order-question";
import { isNewlyCreated } from "modules/common/models/entity";

const schema = OrderMailsSchema();

class Details extends React.PureComponent<DetailsProps> {
  render() {
    const { title, orders, onClose, canClientEdit, hideProject, questionItem, canAddQuestion, confirm, ...rest } =
      this.props;

    const isTwoColumns = (canAddQuestion && isNewlyCreated(confirm.id)) || questionItem.canEditQuestion;
    return (
      <Dialog
        isCloseButtonShown={true}
        isOpen={true}
        onClose={onClose}
        title={title}
        backdropClassName="standard"
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
        className={`figma-dialog ${styles.dialog}`}
        style={{ width: isTwoColumns ? "1300px" : "970px" }}
      >
        <QuestionForm
          {...rest}
          questionItem={questionItem}
          canAddQuestion={canAddQuestion}
          submitLabel={texts.save}
          confirm={confirm}
        >
          {orders && !hideProject && (
            <StandardFormInput
              inline={false}
              name={questionFields.orderId}
              schema={schema}
              small={true}
              className="planr-form-input"
            >
              {({ field, form }) => {
                const projectSelected = (project: OrderDictionaryItemType | null) => {
                  form.setFieldValue(field.name, project ? project.id : "");
                  form.setFieldTouched(field.name, true);
                  form.setFieldValue("employerId", project && project.employer ? project.employer.id : "");
                  form.setFieldTouched("employerId", true);
                };

                const item = orders.asMap[field.value] || null;

                return (
                  <div className={`${Classes.INPUT_GROUP} ${Classes.SMALL}`}>
                    <ProjectSelect
                      className={"full-width-select"}
                      filterable={true}
                      itemPredicate={filterProjectItemPredicate}
                      activeItem={item}
                      items={orders.orders}
                      itemRenderer={renderProjectOption}
                      onItemSelect={projectSelected}
                      resetOnClose={true}
                      inputProps={{
                        placeholder: "Проект",
                      }}
                    >
                      <ProjectSelectedOption option={item} onClear={() => projectSelected(null)} />
                    </ProjectSelect>
                  </div>
                );
              }}
            </StandardFormInput>
          )}
        </QuestionForm>
      </Dialog>
    );
  }
}

export const QuestionDetails = observer(Details);

interface DetailsProps extends FormProps {
  title: string;
  onClose: () => void;
  orders?: OrderDictionaryType;
  hideProject: boolean;
  canClientEdit?: boolean;
  employee: EmployerDictionaryType;
  canAddQuestion?: boolean;
}
