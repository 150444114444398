import { Classes, TextArea } from "@blueprintjs/core";
import { FieldProps, Form, Formik } from "formik";
import { findIndex } from "lodash";
import { FileLink } from "modules/common/components/files/FileLink";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { isNewlyCreated } from "modules/common/models/entity";
import { texts } from "modules/common/texts";
import { UploaderFatory } from "modules/orders-manage/types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Form.module.scss";
import { OrderMailsSchema } from "./validation";

import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { DATE_FORMAT, DATE_TIME_FORMAT, EMPTY_OBJECT_ID } from "modules/common/constants";
import {
  DefaultSelectedOption,
  SimpleSelect,
  filterItemPredicate,
  renderSingleOption,
} from "modules/common/services/form/select";
import {
  MessageType,
  OrderQuestionSnapshotType,
  SaveQuestion,
  questionFields as fields,
  questionFields,
} from "modules/orders-manage/models/order-question";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import moment from "moment";
// export const REQUEST = [MimeTypes.docx, MimeTypes.doc, MimeTypes.xls, MimeTypes.xlsx, MimeTypes.pdf].join(",");

// export const SOURCE = [MimeTypes.docx, MimeTypes.doc, MimeTypes.xls, MimeTypes.xlsx].join(",");

// export const RESULT = [MimeTypes.pdf].join(",");

const schema = OrderMailsSchema();
export const removeLabel = (node: SaveQuestion) => `запись`;

export const QuestionForm: React.FC<FormProps> = (props) => {
  const {
    confirm,
    questionItem,
    onSubmit,
    onRemove,
    children,
    submitLabel,
    baseUrl,
    employee,
    canAddQuestion,
    currentUserId,
    createChild,
    openQuestion,
  } = props;
  const [message, setMessage] = useState("");
  const mounted = useRef(true);
  const remove = useCallback(() => onRemove(confirm), [onRemove, confirm]);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const parent = confirm.parentQuestion ? confirm.parentQuestion : questionItem.parentQuestion;

  let color = "#49a7ff";
  let statusText = "В работе";
  let nowDate = new Date();
  nowDate.setHours(0, 0, 0, 0);
  if (parent) {
    const date = moment(parent.stopDate, DATE_FORMAT).toDate();
    if (parent.completed) {
      color = "#1dd278";
      statusText = "Исполнено";
    } else if (parent.stopDate && date < nowDate && !parent.completed) {
      color = "#e31818";
      statusText = "Просрочено";
    }
  }

  return (
    <Formik
      initialValues={confirm}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);

        if (mounted.current) {
          setSubmitting(false);
        }
      }}
    >
      {(formProps) => {
        let savedEmployer: SelectItem | null = null;
        if (formProps.values.employerId) {
          const employer = employee.employee.find((e) => e.id === formProps.values.employerId);
          if (employer) savedEmployer = { id: employer.id, label: employer.name };
        }

        let savedImplementer: SelectItem | null = null;
        if (formProps.values.implementerId) {
          const employer = employee.employee.find((e) => e.id === formProps.values.implementerId);
          if (employer) savedImplementer = { id: employer.id, label: employer.name };
        }
        return (
          <Form autoComplete="off" className={styles.form}>
            <div>
              <div className={styles.dialog}>
                {((canAddQuestion && isNewlyCreated(confirm.id)) || questionItem.canEditQuestion) && (
                  <div className={styles.columnLeft}>
                    <h2 className={styles.columnHeader}>Вопрос</h2>
                    <StandardFormInput
                      inline={true}
                      name={questionFields.selfQuestion}
                      schema={schema}
                      small={true}
                      className="planr-form-input"
                    >
                      {({ field }: FieldProps) => {
                        return (
                          <div style={{ display: "flex", margin: "0 auto" }}>
                            <PlanrButton
                              type={!field.value ? "primary" : "graybtn"}
                              onClick={() => {
                                if (formProps.values.selfQuestion) {
                                  formProps.setFieldValue(fields.implementerId, "", true);
                                  formProps.setFieldTouched(fields.implementerId, true);
                                  formProps.setFieldValue(fields.employerId, "", true);
                                  formProps.setFieldTouched(fields.employerId, true);
                                }

                                formProps.setFieldValue(field.name, false, true);
                                formProps.setFieldTouched(field.name, true);
                              }}
                              size="small"
                            >
                              Cотруднику
                            </PlanrButton>
                            <PlanrButton
                              style={{ marginLeft: "0px", width: "127px" }}
                              type={field.value ? "primary" : "graybtn"}
                              //icon={field.value ? "general-checkbox" : "general-zero-checkbox"}
                              onClick={() => {
                                formProps.setFieldValue(field.name, true, true);
                                formProps.setFieldTouched(field.name, true);
                              }}
                              size="small"
                            >
                              Для себя
                            </PlanrButton>
                          </div>
                        );
                      }}
                    </StandardFormInput>

                    {children}
                    {/* Вопрос */}

                    {/* Дата истечения */}
                    <StandardFormInput
                      name={questionFields.stopDate}
                      schema={schema}
                      label={formProps.values.id ? "Новый срок исполнения" : "Срок исполнения"}
                      small={true}
                      inline={false}
                      className="planr-form-input"
                    >
                      {({ field, form }) => {
                        return (
                          <div className={`${Classes.INPUT_GROUP}`}>
                            <DatePicker
                              value={moment(field.value, DATE_FORMAT).toDate()}
                              clasName="planr-default-input"
                              onChange={(date) => {
                                form.setFieldValue(field.name, moment(date).format(DATE_FORMAT));
                                form.setFieldTouched(field.name, true);
                              }}
                            />
                          </div>
                        );
                      }}
                    </StandardFormInput>

                    {/* Ответственный (сотрудник) */}
                    {!formProps.values.selfQuestion && (
                      <StandardFormInput
                        name={questionFields.employerId}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                        inline={false}
                      >
                        {(fieldProps) => {
                          const clear = () => {
                            fieldProps.form.setFieldValue(fieldProps.field.name, "");
                            fieldProps.form.setFieldTouched(fieldProps.field.name, true);
                          };

                          return (
                            <div className={`${Classes.INPUT_GROUP}`}>
                              <SimpleSelect
                                className={`full-width-select ${Classes.FILL}`}
                                activeItem={savedEmployer}
                                items={employee.employee}
                                itemRenderer={renderSingleOption}
                                onItemSelect={(i: SelectItem) => {
                                  formProps.setFieldValue(fieldProps.field.name, i.id);
                                  formProps.setFieldTouched(fieldProps.field.name, true);
                                }}
                                filterable={true}
                                inputProps={{
                                  placeholder: texts.search,
                                }}
                                itemPredicate={filterItemPredicate}
                              >
                                <DefaultSelectedOption option={savedEmployer} onClear={clear} />
                              </SimpleSelect>
                            </div>
                          );
                        }}
                      </StandardFormInput>
                    )}
                    <StandardFormInput
                      name={questionFields.description}
                      schema={schema}
                      small={true}
                      className="planr-form-input"
                      inline={false}
                    >
                      {({ field }) => (
                        <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                          <TextArea {...field} growVertically={true} className={Classes.FILL} />
                        </div>
                      )}
                    </StandardFormInput>
                    <div className={styles.requestFiles}>
                      <StandardFormInput
                        name={questionFields.questionFiles}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                      >
                        {({ field, form }: FieldProps) => {
                          const onRemoveFile = (file: FileBase) => {
                            const value = field.value;
                            const index = findIndex(value, (f: FileBase) => f.fileId === file.fileId);

                            if (index >= 0) {
                              const newValue = [...value.slice(0, index), ...value.slice(index + 1)];
                              form.setFieldValue(field.name, newValue);
                              form.setFieldTouched(field.name, true);
                            }
                          };

                          const onFileSelected = async (file: File) => {
                            const { upload } = props;
                            const success = await upload(file);

                            if (success) {
                              const value = [...field.value, success];
                              form.setFieldValue(field.name, value);
                              form.setFieldTouched(field.name);
                            }
                          };

                          return (
                            <div className={styles.fileList}>
                              <label className="bp3-label">Прикрепить файл:</label>

                              <PlanRDndUploader
                                accept={"*"}
                                onFileSelected={onFileSelected}
                                withLoader={true}
                                multiple={true}
                                style={{ maxWidth: "600px" }}
                              />
                              <div className={styles.filesList}>
                                {field.value.map((doc: FileBase, index: number) => (
                                  <FileLink
                                    baseUrl={baseUrl}
                                    file={doc}
                                    key={index}
                                    onRemove={onRemoveFile}
                                    style={{ maxWidth: "600px" }}
                                  />
                                ))}
                              </div>
                            </div>
                          );
                        }}
                      </StandardFormInput>
                    </div>
                  </div>
                )}
                {(questionItem.canAddImplementer ||
                  questionItem.canAddAnswer ||
                  questionItem.canEditQuestion ||
                  canAddQuestion ||
                  questionItem.canChangeCompleted) && (
                  <div className={styles.columnMid}>
                    <h2 className={styles.columnHeader}>Ответ</h2>
                    {/* Статусы */}
                    {((canAddQuestion && isNewlyCreated(confirm.id)) || questionItem.canChangeCompleted) && (
                      <StandardFormInput
                        inline={true}
                        name={questionFields.completed}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                      >
                        {({ field }: FieldProps) => {
                          return (
                            <PlanrButton
                              type="graybtn"
                              icon={field.value ? "general-checkbox" : "general-zero-checkbox"}
                              onClick={() => {
                                formProps.setFieldValue(field.name, !field.value, true);
                                formProps.setFieldTouched(field.name, true);
                              }}
                            />
                          );
                        }}
                      </StandardFormInput>
                    )}
                    {/* Исполнитель (сотрудник) */}
                    {((canAddQuestion && isNewlyCreated(confirm.id)) || questionItem.canAddImplementer) &&
                      !formProps.values.selfQuestion && (
                        <StandardFormInput
                          name={questionFields.implementerId}
                          schema={schema}
                          small={true}
                          className="planr-form-input"
                          inline={false}
                        >
                          {(fieldProps) => {
                            const clear = () => {
                              fieldProps.form.setFieldValue(fieldProps.field.name, "");
                              fieldProps.form.setFieldTouched(fieldProps.field.name, true);
                            };

                            return (
                              <div className={`${Classes.INPUT_GROUP}`}>
                                <SimpleSelect
                                  className={`full-width-select ${Classes.FILL}`}
                                  activeItem={savedImplementer}
                                  items={employee.employee}
                                  itemRenderer={renderSingleOption}
                                  onItemSelect={(i: SelectItem) => {
                                    formProps.setFieldValue(fieldProps.field.name, i.id);
                                    formProps.setFieldTouched(fieldProps.field.name, true);
                                  }}
                                  filterable={true}
                                  inputProps={{
                                    placeholder: texts.search,
                                  }}
                                  itemPredicate={filterItemPredicate}
                                >
                                  <DefaultSelectedOption option={savedImplementer} onClear={clear} />
                                </SimpleSelect>
                              </div>
                            );
                          }}
                        </StandardFormInput>
                      )}

                    {((canAddQuestion && isNewlyCreated(confirm.id)) || questionItem.canAddAnswer) && (
                      <>
                        <StandardFormInput
                          name={questionFields.answer}
                          schema={schema}
                          small={true}
                          className="planr-form-input"
                          inline={false}
                        >
                          {({ field }) => (
                            <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                              <TextArea {...field} growVertically={true} className={Classes.FILL} />
                            </div>
                          )}
                        </StandardFormInput>

                        <StandardFormInput
                          name={questionFields.answerFiles}
                          schema={schema}
                          small={true}
                          className="planr-form-input"
                        >
                          {({ field, form }: FieldProps) => {
                            const onRemoveFile = (file: FileBase) => {
                              const value = field.value;
                              const index = findIndex(value, (f: FileBase) => f.fileId === file.fileId);

                              if (index >= 0) {
                                const newValue = [...value.slice(0, index), ...value.slice(index + 1)];
                                form.setFieldValue(field.name, newValue);
                                form.setFieldTouched(field.name, true);
                              }
                            };

                            const onFileSelected = async (file: File) => {
                              const { upload } = props;
                              const success = await upload(file);

                              if (success) {
                                const value = [...field.value, success];
                                form.setFieldValue(field.name, value);
                                form.setFieldTouched(field.name);
                              }
                            };

                            return (
                              <div className={styles.fileList}>
                                <label className="bp3-label">Файлы ответа:</label>

                                <PlanRDndUploader
                                  accept={"*"}
                                  onFileSelected={onFileSelected}
                                  withLoader={true}
                                  multiple={true}
                                />
                                <div className={styles.filesList}>
                                  {field.value.map((doc: FileBase, index: number) => (
                                    <FileLink baseUrl={baseUrl} file={doc} key={index} onRemove={onRemoveFile} />
                                  ))}
                                </div>
                              </div>
                            );
                          }}
                        </StandardFormInput>
                      </>
                    )}
                  </div>
                )}
                {(questionItem.canAddImplementer ||
                  questionItem.canAddAnswer ||
                  questionItem.canEditQuestion ||
                  canAddQuestion ||
                  questionItem.canChangeCompleted) && (
                  <div className={styles.columnRight}>
                    <h2 className={styles.columnHeader}>Сообщения</h2>
                    {/* Статусы */}

                    <StandardFormInput
                      name={questionFields.messages}
                      schema={schema}
                      small={true}
                      className="planr-form-input"
                      inline={false}
                    >
                      {({ field, form }) => {
                        return (
                          <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                            <div style={{ display: "flex", marginBottom: "8px" }}>
                              <TextArea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                growVertically={true}
                                className={Classes.FILL}
                                style={{ marginRight: "5px", maxHeight: "100px" }}
                              />
                              <PlanrButton
                                size="small"
                                type="lightblueish"
                                onClick={() => {
                                  let oldMsg = [...field.value];
                                  const newMessage: MessageType = {
                                    id: EMPTY_OBJECT_ID,
                                    description: message,
                                    employer: null,
                                    day: "",
                                    hours: "",
                                    date: moment(new Date()).format(DATE_TIME_FORMAT),
                                  };
                                  let newMsg = [newMessage, ...oldMsg];

                                  form.setFieldValue(field.name, newMsg);
                                  form.setFieldTouched(field.name, true);
                                  setMessage("");
                                }}
                              >
                                Добавить
                              </PlanrButton>
                            </div>
                            <div className={styles.messages}>
                              {field.value.map((msg: MessageType, index: number) => (
                                <div key={index} className={styles.message}>
                                  {(msg.employer === null || currentUserId === msg.employer?.id) && (
                                    <div className={styles.messageBtn}>
                                      <PlanrButton
                                        type="danger"
                                        size="small"
                                        style={{ maxHeight: "20px" }}
                                        icon="general-trash"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          form.setFieldValue(field.name, [
                                            ...field.value.slice(0, index),
                                            ...field.value.slice(index + 1),
                                          ]);
                                          form.setFieldTouched(field.name, true);
                                        }}
                                      />
                                    </div>
                                  )}
                                  <div
                                    className={styles.messageHeader}
                                    style={
                                      !msg.employer || msg.employer?.id === currentUserId ? { color: "#1dd278" } : {}
                                    }
                                  >
                                    <div>{msg.employer ? msg.employer.name : "Вы"}</div>
                                    <div> {!msg.day ? msg.date : `${msg.day} ${msg.hours}`}</div>
                                  </div>
                                  <div className={styles.messageText}>{msg.description}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        );
                      }}
                    </StandardFormInput>
                  </div>
                )}
              </div>
              <div className={styles.otherQuestions}>
                <div className={styles.questionCol}>
                  {questionItem.id && (
                    <div className={styles.newQuestion}>
                      {(questionItem.children.length > 0 || questionItem.canEditQuestion) && (
                        <div> Список подзадач:</div>
                      )}
                      {questionItem.canEditQuestion && (
                        <PlanrButton
                          type="secondary"
                          size="small"
                          style={{ marginLeft: "16px" }}
                          onClick={() => {
                            createChild(questionItem);
                          }}
                        >
                          Создать подзадачу
                        </PlanrButton>
                      )}
                    </div>
                  )}
                  {questionItem.children.length > 0 && (
                    <div className={styles.questionItems}>
                      {questionItem.children.map((q) => {
                        let color = "#49a7ff";
                        let statusText = "В работе";
                        let nowDate = new Date();
                        nowDate.setHours(0, 0, 0, 0);
                        const date = moment(q.stopDate, DATE_FORMAT).toDate();
                        if (q.stopDate && date < nowDate && !q.completed) {
                          color = "#e31818";
                          statusText = "Просрочено";
                        }

                        if (q.completed) {
                          color = "#1dd278";
                          statusText = "Исполнено";
                        }
                        return (
                          <div
                            className={styles.questionRow}
                            key={q.id}
                            onClick={() => {
                              openQuestion(q);
                            }}
                          >
                            <div className={styles.number}>{q.number}</div>
                            <div className={styles.description}>{q.description}</div>
                            <div style={{ backgroundColor: color }} className={styles.status}>
                              {statusText}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                {parent && parent.id && (
                  <div className={styles.questionCol}>
                    <div className={styles.newQuestion}>
                      <div>Относится к заданию:</div>
                      <PlanrButton
                        type="secondary"
                        size="small"
                        style={{ marginLeft: "16px", opacity: 0 }}
                        onClick={() => {}}
                      >
                        Создать подзадачу
                      </PlanrButton>
                    </div>
                    <div className={styles.questionItems}>
                      <div
                        className={styles.questionRowParent}
                        onClick={() => {
                          openQuestion(parent);
                        }}
                      >
                        <div className={styles.number}>{parent.number}</div>
                        <div className={styles.description}>{parent.description}</div>
                        <div style={{ backgroundColor: color }} className={styles.status}>
                          {statusText}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <StandardFormButtons
                {...formProps}
                isRemoved={false}
                what={removeLabel(confirm)}
                isNewlyCreated={isNewlyCreated(confirm.id)}
                onRemove={remove}
                submitLabel={submitLabel || texts.ready}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export interface FormProps extends UploaderFatory {
  baseUrl: string;
  confirm: SaveQuestion;
  questionItem: OrderQuestionSnapshotType;
  onSubmit: (mail: SaveQuestion) => Promise<any>;
  onRemove: (mail: SaveQuestion) => void;
  submitLabel?: string;
  canAddQuestion?: boolean;
  canClientEdit?: boolean;
  employee: EmployerDictionaryType;
  currentUserId: string;
  createChild: (parent?: OrderQuestionSnapshotType) => void;
  openQuestion: (parent?: any | null) => void;
}
