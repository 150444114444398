import { Classes, MenuItem } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import {
  filterProjectItemPredicate,
  ProjectSelect,
  ProjectSelectedOption,
  renderProjectOption,
} from "modules/common/components/form/ProjectSelect";
import { Search } from "modules/common/components/form/Search";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import {
  DefaultSelectedOption,
  filterItemPredicate,
  renderSingleOption,
  SimpleSelect,
} from "modules/common/services/form/select";
import { texts } from "modules/common/texts";
import { OrderDictionaryItemType, OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { OrderQuestionSnapshotType } from "modules/orders-manage/models/order-question";
// import { Constants } from "modules/root/models/constants";
import {
  EmployerDictionaryItemType,
  EmployerDictionaryType,
} from "modules/spending/employee/models/employee-dictionary";
import React, { useCallback, useState } from "react";
import { OrderQuestionStoreType } from "../models/store";
import styles from "./Filters.module.scss";
import { FiltersContract } from "./types";

const StatusMultiSelect = MultiSelect.ofType<SelectItem>();

export const Filters: React.FC<FiltersProps> = (props) => {
  const [orderItem, setorderItem] = useState<OrderDictionaryItemType | null>(null);

  const [employerItem, setEmployerItem] = useState<{ id: string; label: string } | null>(null);

  const [implementerItem, setImplementerItem] = useState<{ id: string; label: string } | null>(null);

  const { children, query, orders, setQuery, hideProject, employers, data, selectedStatuses, statuses } = props;
  const { setOrderId, setEmployerId, setImplementerId, itemsEqual, onItemSelect, onRemove, tagRender, clearStatus } =
    props;

  const projectSelected = useCallback(
    (project: OrderDictionaryItemType | null) => {
      if (project !== null) {
        setOrderId(project.id);
        setorderItem(project);
      } else {
        setOrderId("");
        setorderItem(null);
      }
    },
    [setOrderId]
  );

  const employerSelected = useCallback(
    (item: { id: string; label: string } | null) => {
      if (item !== null) {
        setEmployerId(item.id);
        setEmployerItem(item);
      } else {
        setEmployerId("");
        setEmployerItem(null);
      }
    },
    [setEmployerId]
  );

  const implementerSelected = useCallback(
    (item: { id: string; label: string } | null) => {
      if (item !== null) {
        setImplementerId(item.id);
        setImplementerItem(item);
      } else {
        setImplementerId("");
        setImplementerItem(null);
      }
    },
    [setImplementerId]
  );

  // const filterEmployeesByRole = (role: "employer" | "implementer") => {
  //   if (employers?.employee && data?.length) {
  //     const ids = data.filter((a) => a[role]).map((a) => a[role]?.id);
  //     return employers.employee.filter((e) => ids.includes(e.id));
  //   }
  //   return [];
  // };
  // const employersList = filterEmployeesByRole("employer");
  // const implementorList = filterEmployeesByRole("implementer");
  // console.info(employersList, implementorList, data);
  return (
    <div className={styles.filters}>
      <div style={{ display: "flex" }}>
        <div className={styles.filterSelect}>
          <StatusMultiSelect
            activeItem={null}
            className={`full-width-select ${Classes.FILL}`}
            itemRenderer={renderSingleOption}
            itemsEqual={itemsEqual}
            selectedItems={selectedStatuses}
            items={statuses}
            onItemSelect={onItemSelect}
            placeholder="Все записи"
            itemPredicate={filterItemPredicate}
            tagRenderer={tagRender}
            noResults={<MenuItem disabled={true} text={texts.noData} />}
            popoverProps={{ minimal: true }}
            resetOnSelect={true}
            onRemove={onRemove}
          />
          <GeneralIcon
            type={selectedStatuses.length > 0 ? "general-cross-small" : "general-chevron-down"}
            onClick={clearStatus}
            style={{
              marginTop: "5px",
              marginLeft: "5px",
              color: selectedStatuses.length > 0 ? "#E31818" : "#00273D",
              position: "absolute",
              right: "10px",
              top: "5px",
              cursor: "pointer",
            }}
          />
        </div>
        {orders && !hideProject && (
          <div className={styles.orderFilter} style={!hideProject ? { width: "230px" } : {}}>
            <ProjectSelect
              className={`${Classes.FILL} planr-default-input`}
              filterable={true}
              itemPredicate={filterProjectItemPredicate}
              activeItem={orderItem}
              items={orders.orders}
              itemRenderer={renderProjectOption}
              onItemSelect={projectSelected}
              resetOnClose={true}
              inputProps={{
                placeholder: "Проект",
              }}
              popoverProps={{
                boundary: "viewport",
                popoverClassName: "import-spendings-table__select",
              }}
            >
              <ProjectSelectedOption option={orderItem} onClear={() => projectSelected(null)} noData="Объект" />
            </ProjectSelect>
          </div>
        )}
        {employers?.employee && data && (
          <>
            <div className={styles.orderFilter} style={!hideProject ? { width: "230px" } : {}}>
              <SimpleSelect
                className={`${Classes.FILL} planr-default-input`}
                filterable={true}
                itemPredicate={filterItemPredicate}
                activeItem={employerItem}
                items={[...employers.employee].filter((e) =>
                  data.map((d) => d.employer && d.employer.id).includes(e.id)
                )}
                itemRenderer={renderSingleOption}
                onItemSelect={employerSelected}
                resetOnClose={true}
                inputProps={{
                  placeholder: "Проект",
                }}
                popoverProps={{
                  boundary: "viewport",
                  popoverClassName: "import-spendings-table__select",
                }}
              >
                <DefaultSelectedOption
                  option={employerItem}
                  onClear={() => employerSelected(null)}
                  what="Ответственный"
                />
              </SimpleSelect>
            </div>
            <div className={styles.orderFilter} style={!hideProject ? { width: "230px" } : {}}>
              <SimpleSelect
                className={`${Classes.FILL} planr-default-input`}
                filterable={true}
                itemPredicate={filterItemPredicate}
                activeItem={implementerItem}
                items={[...employers.employee].filter((e) =>
                  data.map((d) => d.implementer && d.implementer.id).includes(e.id)
                )}
                itemRenderer={renderSingleOption}
                onItemSelect={implementerSelected}
                resetOnClose={true}
                inputProps={{
                  placeholder: "Проект",
                }}
                popoverProps={{
                  boundary: "viewport",
                  popoverClassName: "import-spendings-table__select",
                }}
              >
                <DefaultSelectedOption
                  option={implementerItem}
                  onClear={() => implementerSelected(null)}
                  what="Исполнитель"
                />
              </SimpleSelect>
            </div>
          </>
        )}
      </div>
      <div style={{ display: "flex", marginRight: "20px" }}>
        {children}
        <div className={styles.tools}>
          <Search query={query} onSearch={setQuery} />
        </div>
      </div>
    </div>
  );
};

interface FiltersProps extends FiltersContract {
  orders?: OrderDictionaryType;
  employers?: EmployerDictionaryType;
  hideProject?: boolean;
  store: OrderQuestionStoreType;
  data?: OrderQuestionSnapshotType[];
  employerList?: EmployerDictionaryItemType[];
  implementerList?: EmployerDictionaryItemType[];
  statuses: SelectItem[];
  selectedStatuses: SelectItem[];
  itemsEqual: any;
  onItemSelect: any;
  onRemove: any;
  tagRender: any;
  clearStatus: any;
}

interface StatusFilterHeaderProps {
  value: string;
  onChange: (value: any) => void;
  currentOptions: { [k: string]: any };
  width: number;
  filter?: string;
}

// const StatusFilterHeader = observer(
//   class extends React.Component<StatusFilterHeaderProps> {
//     render() {
//       const { width, value, currentOptions } = this.props;

//       const option = currentOptions[value];
//       return (
//         <SimpleSelect
//           className={`${Classes.FILL} planr-default-input`}
//           filterable={false}
//           items={Object.values(currentOptions)}
//           itemRenderer={renderSingleOption}
//           onItemSelect={this.onItemSelect}
//           popoverProps={{
//             boundary: "viewport",
//             popoverClassName: "import-spendings-table__select",
//           }}
//         >
//           <DefaultSelectedOption
//             option={option}
//             empty={!!Constants.employerDocumentType.values.length}
//             style={{ overflow: "hidden", maxWidth: `${width - 3}px` }}
//           />
//         </SimpleSelect>
//       );
//     }

//     onItemSelect = (item: SelectItem) => this.props.onChange(item);
//   }
// );
