import React, { useCallback } from "react";
import { Button, MaybeElement } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import { ItemRenderer, ItemPredicate, Select, SelectProps } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core/lib/esnext/components";
import { DictionaryItemType } from "modules/common/models/entity";
import classnames from "classnames";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export const prevent = (e: React.MouseEvent) => e.stopPropagation();

export function SelectFactory<TItem>(matchWidth = true) {
  const Papa = Select.ofType<TItem>();

  const result: React.FC<SelectProps<TItem>> = ({ popoverProps, matchTargetWidth, ...rest }) => {
    const base = popoverProps || {};

    const popProps = Object.assign({}, base, {
      modifiers: {
        offset: { offset: 0 },
      },
    });

    return (
      <Papa
        {...rest}
        matchTargetWidth={matchTargetWidth ?? matchWidth}
        popoverProps={popProps}
        className={classnames("select", rest.className)}
      />
    );
  };

  return result;
}

export const SimpleSelect = SelectFactory<SelectItem>(true);

export interface RendererProps {
  option: DictionaryItemType | null | undefined;
  disabled?: boolean;
  icon: JSX.Element;
  text: string;
  style?: React.CSSProperties;
}

export interface SelectedOptionProps {
  option: DictionaryItemType | null | undefined;
  onClear?: () => void;
  what?: string;
  empty?: boolean;
  disabled?: boolean;
  render?: (props: RendererProps) => any;
  style?: React.CSSProperties;
}

const DefaultRenderer = ({ disabled, icon, text, style }: RendererProps) => {
  return (
    <Button disabled={disabled} style={style} fill={true} rightIcon={icon} className="selected-option" text={text} />
  );
};

export const DefaultSelectedOption = ({
  option,
  disabled,
  onClear,
  what,
  empty,
  render,
  style,
}: SelectedOptionProps) => {
  const clear = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      !disabled && onClear && onClear();
    },
    [onClear, disabled]
  );

  const icon =
    onClear && option ? (
      <>
        <GeneralIcon type="general-cross-small" onClick={clear} style={{ color: "#E31818" }} />
        <GeneralIcon type="general-chevron-down" />
      </>
    ) : (
      <GeneralIcon type="general-chevron-down" />
    );

  const nothing = what === undefined ? " " : what;
  const text = empty ? texts.noData : option ? option.label || nothing : nothing;

  return (render || DefaultRenderer)({
    icon,
    option,
    text,
    disabled,
    style,
  });
};

export const renderSingleOptionFactory = (
  leftElement: (item: SelectItem) => MaybeElement
): ItemRenderer<SelectItem> => {
  return (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const text = item.label || texts.undefined;
    const style: React.CSSProperties | undefined = item.color
      ? {
          backgroundColor: item.color,
        }
      : undefined;

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.id}
        onClick={handleClick}
        text={text}
        className={item.removed ? "removed" : ""}
        icon={leftElement(item)}
        style={style ? style : item.width ? { width: item.width } : {}}
      />
    );
  };
};

export const renderSingleOption = renderSingleOptionFactory(() => null);

export const filterItemPredicate: ItemPredicate<any> = (query, item, _index, exactMatch) => {
  const normalizedTitle = item.label.toLowerCase();
  const normalizedQuery = query.toLowerCase().trim();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return normalizedTitle.indexOf(normalizedQuery) >= 0;
  }
};

export function buildOptions(
  include: DictionaryItemType | any | null,
  items: DictionaryItemType[] | any,
  optional = false
) {
  const result: TStringMap<SelectItem> = optional
    ? {
        "": { id: "", label: texts.undefined },
      }
    : {};

  items.forEach((item: any) => {
    result[item.id] = item;
  });

  if (include && !result[include.id]) {
    result[include.id] = include;
  }

  return result;
}
