import {
  ColDef,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  RowClickedEvent,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { Pager } from "modules/common/components/table/Pager";
import { routes } from "modules/common/routes";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import { orderTitle } from "modules/orders-manage/models/order";
import { StringifiedOrderLinkType } from "modules/orders-manage/models/order-link";
import { agGridLocale } from "modules/root/services/locale";
import React from "react";
import { Link } from "react-router-dom";
import { Actions } from "./Actons";
import { QuestionDetails } from "./Details";
import { Filters } from "./Filters";
import styles from "./List.module.scss";
import { StoreContract } from "./types";

import { FileLink } from "modules/common/components/files/FileLink";
import { Loader } from "modules/common/components/loader/Loader";
import { DATE_FORMAT } from "modules/common/constants";
import { eat } from "modules/common/services/typescript";
import { OrderDictionaryItemType } from "modules/orders-manage/models/order-dictionary";
import { mapQuestion, OrderQuestionSnapshotType, SaveQuestion } from "modules/orders-manage/models/order-question";
import moment from "moment";
import { OrderQuestionStoreType } from "../models/store";
import { isNewlyCreated } from "modules/common/models/entity";

const statuses = [
  { id: "Исполнено", label: "Исполнено", color: "", canBeApplied: true, type: "Исполнено" },
  { id: "В работе", label: "В работе", color: "", canBeApplied: true, type: "В работе" },
  { id: "Просрочено", label: "Просрочено", color: "", canBeApplied: true, type: "Просрочено" },
];

const removeLabel = (what: OrderQuestionSnapshotType[]) => {
  if (!what) {
    return "";
  }

  const prefix = what.length > 1 ? "записи" : "запись";

  return `${prefix} `;
};

const modules = [MasterDetailModule, MenuModule, ...defaultModules];

export class QuestionList extends React.PureComponent<QuestionsListProps, QuestionsListState> {
  private options = DefaultAgGridOptions(true);
  private gridApi: GridApi | null = null;
  constructor(props: QuestionsListProps) {
    super(props);

    const { columns, canManage, baseUrl } = props;

    this.state = {
      selection: [],
      editable: null,
      question: null,
      assigment: [],
      colDefs: columns(canManage, baseUrl),
      showTools: false,
      loading: false,
      orders: [],
    };
  }

  componentDidUpdate(prevProps: QuestionsListProps) {
    if (this.props.canManage !== prevProps.canManage) {
      this.setState({ colDefs: this.props.columns(this.props.canManage, this.props.baseUrl) });
    }

    if (this.props.current !== prevProps.current) {
      if (this.gridApi) {
        const row = this.props.current ? this.gridApi.getRowNode(this.getRowNodeId(this.props.current)) : null;

        if (row) {
          row.setSelected(true, true);
        } else {
          this.gridApi.deselectAll();
        }
      }
    }

    // select single mail
    if (this.props.data !== prevProps.data && this.props.data.length === 1) {
      this.props.onQuestionSelected(this.props.data[0]);
    }
  }

  render() {
    const store = this.props;
    const { storre } = this.props;
    const { canManage, data, rowBuffer, hideProject, isActiveTab, employee, canAddQuestion, currentUserId, setOrder } =
      this.props;
    const { editable, colDefs, selection, showTools, question, loading } = this.state;

    let completedData = 0;
    let workData = 0;
    let expiredData = 0;

    data.forEach((o) => {
      const [day, month, year] = o.stopDate.split(".");
      // Создаем объект Date (месяцы в JavaScript начинаются с 0, поэтому month - 1)
      const stopDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      const currentDate = new Date();

      if (o.completed) {
        completedData++;
      } else if (!o.completed && stopDate >= currentDate) {
        workData++;
      } else if (!o.completed && stopDate < currentDate) {
        expiredData++;
      }
    });

    let selectedStatuses: any[] = [];
    storre.selectedStatuses.forEach((item) =>
      statuses.forEach((status) => {
        if (status.id === item) {
          selectedStatuses.push(status);
        }
      })
    );
    return (
      <div className={`mails ${styles.list}`}>
        {loading && <Loader active />}

        {!!editable && !!question && (
          <QuestionDetails
            currentUserId={currentUserId}
            confirm={editable}
            questionItem={question}
            onClose={this.closeDialog}
            orders={store.orders}
            title={isNewlyCreated(editable.id) ? "Новое задание" : `Задание ${question.number}`}
            employee={employee}
            onRemove={this.removeSingleQuestion}
            onSubmit={this.onSubmit}
            upload={store.upload}
            baseUrl={store.baseUrl}
            hideProject={hideProject}
            canAddQuestion={canAddQuestion}
            createChild={this.openNewDialog}
            openQuestion={this.openDialog}
          />
        )}

        <RemoveConfirmation<OrderQuestionSnapshotType[]>
          observation={data}
          what={removeLabel}
          onConfirmed={store.remove}
          render={({ confirmRemoving }) => {
            const getContextMenuItems = canManage
              ? ({ node }: GetContextMenuItemsParams) =>
                  agGridEntityContextMenu({
                    onChange: () => this.openDialog(node.data),
                    onRemove: () => confirmRemoving([node.data]),
                  })
              : undefined;

            return (
              <>
                <div className={styles.mobileTools}>
                  {canManage && (
                    <Actions
                      questions={this.state.selection}
                      onRemove={confirmRemoving}
                      onAdd={() => {
                        this.openNewDialog();
                      }}
                      canAddQuestion={canAddQuestion}
                    />
                  )}
                  <PlanrButton
                    type={showTools ? "blueish" : "lightblueish"}
                    icon="general-dots-more"
                    round
                    onClick={this.toggleShowTools}
                  />
                </div>
                {showTools && (
                  <div className={styles.dialogTools}>
                    <Filters
                      filtersType={store.filtersType}
                      query={store.query}
                      setFiltersType={store.setFiltersType}
                      setQuery={store.setQuery}
                      toggleFiltersStatus={store.toggleFiltersStatus}
                      setOrderId={store.setOrderId}
                      hideProject={hideProject}
                      store={storre}
                      orders={store.orders}
                      employers={store.employee}
                      setEmployerId={store.setEmployerId}
                      setImplementerId={store.setImplementerId}
                      data={data}
                      itemsEqual={this.areStatusesEquals}
                      onItemSelect={this.selectStatus}
                      onRemove={storre.removeStatuses}
                      tagRender={this.renderTag}
                      clearStatus={storre.clearStatuses}
                      statuses={statuses}
                      selectedStatuses={selectedStatuses}
                    >
                      <div className={styles.actions}>
                        <PlanrButton
                          type="graybtn"
                          icon={"general-download"}
                          onClick={this.batchDownload}
                          round={true}
                          title="Скачать файлы результата"
                        />
                        <PlanrButton
                          type="graybtn"
                          icon={"general-print"}
                          onClick={this.batchPrint}
                          round={true}
                          title="Печать"
                        />
                      </div>
                    </Filters>
                  </div>
                )}
                <div className={styles.tools}>
                  {canManage && (
                    <Actions
                      questions={this.state.selection}
                      onRemove={confirmRemoving}
                      onAdd={this.openNewDialog}
                      canAddQuestion={canAddQuestion}
                    />
                  )}
                  <Filters
                    filtersType={store.filtersType}
                    query={store.query}
                    setFiltersType={store.setFiltersType}
                    setQuery={store.setQuery}
                    toggleFiltersStatus={store.toggleFiltersStatus}
                    setOrderId={store.setOrderId}
                    hideProject={hideProject}
                    store={storre}
                    orders={store.orders}
                    employers={store.employee}
                    setEmployerId={store.setEmployerId}
                    setImplementerId={store.setImplementerId}
                    data={data}
                    itemsEqual={this.areStatusesEquals}
                    onItemSelect={this.selectStatus}
                    onRemove={storre.removeStatuses}
                    tagRender={this.renderTag}
                    clearStatus={storre.clearStatuses}
                    statuses={statuses}
                    selectedStatuses={selectedStatuses}
                  >
                    <div className={styles.actions}>
                      {setOrder && (
                        <PlanrButton
                          type="greenish"
                          onClick={() => {
                            setOrder();
                          }}
                        >
                          Отобразить все
                        </PlanrButton>
                      )}
                      {/* <PlanrButton
                        type="graybtn"
                        icon={"general-download"}
                        onClick={this.batchDownload}
                        round={true}
                        title="Скачать файлы результата"
                      /> */}
                      <PlanrButton
                        type="graybtn"
                        icon={"general-print"}
                        onClick={this.batchPrint}
                        round={true}
                        title="Печать"
                      />
                    </div>
                  </Filters>
                </div>
                <div className={styles.statuses}>
                  <div className={styles.dataItems}>
                    <div className={styles.dataAll}>Всего: {data.length}</div>
                    <div className={styles.dataCompleted}>Исполнено: {completedData}</div>
                    <div className={styles.dataWork}>В работе: {workData}</div>
                    <div className={styles.dataExpired}>Просрочено: {expiredData}</div>
                  </div>
                </div>
                {isActiveTab && (
                  <GridTheme bordered={true} className={styles.Mailtable}>
                    <AgGridReact
                      rowStyle={{
                        border: "none",
                        borderBottom: "1px solid #EDF0F2",
                        fontFamily: "GothamPro, Arial, sans-serif",
                        color: "#00273D",
                        fontSize: "12px",
                      }}
                      getRowStyle={this.getRowStyle}
                      rowSelection="multiple"
                      enableCellTextSelection={true}
                      columnDefs={colDefs}
                      rowData={data}
                      localeText={agGridLocale}
                      getRowNodeId={this.getRowNodeId}
                      gridOptions={this.options}
                      immutableData={true}
                      detailCellRenderer="detailCellRenderer"
                      modules={modules}
                      masterDetail={true}
                      groupDefaultExpanded={0}
                      context={this}
                      suppressCellSelection={true}
                      onGridReady={this.onGridReady}
                      onSortChanged={store.resorted}
                      onSelectionChanged={this.onSelectionChanged}
                      onRowDoubleClicked={this.onRowDoubleClicked}
                      getContextMenuItems={getContextMenuItems}
                      onRowClicked={this.onRowClicked}
                      rowBuffer={rowBuffer}
                      onRowDataUpdated={() => {
                        this.gridApi?.resetRowHeights();
                      }}
                      detailRowAutoHeight
                    />
                  </GridTheme>
                )}

                <div className={styles.cardList}>
                  {data.map((row) => {
                    let color = "#ff4949";
                    // if (row.type === Constants.orderConfirmTypes[Constants.orderConfirmTypes.length - 1]) {
                    //   color = "#63ff63";
                    // }
                    const selected = selection.find((s) => s.id === row.id);
                    const order = row.order as StringifiedOrderLinkType;

                    return (
                      <div key={row.id}>
                        {/* <div className={selected ? styles.cardSelected : styles.card}> */}
                        <div className={styles.card}>
                          <div className={styles.line} style={{ backgroundColor: color }}></div>
                          <div className={styles.mid}>
                            <div className={styles.row}>{/* <div className={styles.rowItem}>{row.type}</div> */}</div>
                            <div className={styles.row}>
                              <div className={styles.rowItem}>{row.day}</div>
                            </div>
                            {order && (
                              <div className={styles.row}>
                                <div className={styles.rowItem}>
                                  <Link to={routes.orders.details(order.id, OrderTabs.orderQuestion)}>
                                    {orderTitle({ fullInventoryNumber: order.inventoryNumber, name: order.name })}
                                  </Link>
                                </div>
                              </div>
                            )}
                            <div className={styles.fileRow}>
                              {row.questionFiles.map((file, index) => (
                                <FileLink
                                  baseUrl={store.baseUrl}
                                  file={{ fileId: file.id, fileName: `Файл вопроса №${index + 1}` }}
                                  key={file.id}
                                  onRemove={eat}
                                  readOnly
                                  style={{ width: "240px" }}
                                />
                              ))}
                            </div>
                            <div className={styles.fileRow}>
                              {row.answerFiles.map((file, index) => (
                                <FileLink
                                  baseUrl={store.baseUrl}
                                  file={{ fileId: file.id, fileName: `Файл ответа №${index + 1}` }}
                                  key={file.id}
                                  onRemove={eat}
                                  readOnly
                                  style={{ width: "240px" }}
                                />
                              ))}
                            </div>
                          </div>
                          <div className={styles.edit}>
                            <PlanrButton
                              type="lightblueish"
                              icon="general-edit"
                              size="small"
                              onClick={() => {
                                this.props.canManage && this.openDialog(row);
                              }}
                            />
                            <PlanrButton
                              type="neutral"
                              icon={selected ? "general-checkbox" : "general-zero-checkbox"}
                              size="small"
                              style={{ marginLeft: "0px", marginTop: "5px" }}
                              onClick={() => {
                                let newArr = [...selection];
                                const index = newArr.findIndex((s) => s.id === row.id);
                                if (index > -1) {
                                  newArr.splice(index, 1);
                                } else {
                                  newArr.push(row);
                                }
                                this.setState({ selection: [...newArr] });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {store.pager && (
                    <Pager
                      itemsPerPage={store.pager.pageSize}
                      page={store.pager.page}
                      totalItems={store.pager.totalCount}
                      pageSelected={store.setPage}
                      small={true}
                      hideIfEmpty={true}
                      pagesNumber={7}
                    />
                  )}
                </div>
              </>
            );
          }}
        />
        <div className={styles.pages}>
          {store.pager && (
            <Pager
              itemsPerPage={store.pager.pageSize}
              page={store.pager.page}
              totalItems={store.pager.totalCount}
              pageSelected={store.setPage}
              small={true}
              hideIfEmpty={true}
            />
          )}
        </div>
      </div>
    );
  }
  setCompleted = async (id: string) => {
    this.setState({ loading: true });
    await this.props.storre.setCompleted(id);
    this.gridApi?.redrawRows();
    this.setState({ loading: false });
  };

  getRowStyle = (params: any) => {
    if (params && params.data) {
      let nowDate = new Date();
      nowDate.setHours(0, 0, 0, 0);
      const date = moment(params.data.stopDate, DATE_FORMAT).toDate();
      if (params.data.completed) {
        return { "background-color": "#00fa7d1f" };
      }
      if (params.data.stopDate && date < nowDate && !params.data.completed) {
        return { "background-color": "#fd00002f" };
      }
      if (
        !params.data.completed &&
        params.data.stopDate &&
        date >= nowDate &&
        (params.data.answer || params.data.answerFiles.length > 0)
      ) {
        return { "background-color": "#fceb0036" };
      }
      return null;
    }
  };

  removeSingleQuestion = async (mail: SaveQuestion) => {
    const success = await this.props.remove([mail]);
    success && this.closeDialog();
  };

  areStatusesEquals = (status1: SelectItem, status2: SelectItem) => {
    return status1.id === status2.id;
  };

  selectStatus = (status: SelectItem) => {
    const { storre } = this.props;
    storre.setStatuses(status);
  };

  renderTag = (status: SelectItem) => {
    return status.label;
  };

  startAssignment = (mails: OrderQuestionSnapshotType[]) => this.setState({ assigment: mails });

  openNewDialog = (parent?: OrderQuestionSnapshotType) => {
    this.openDialog(this.props.mailFactory(), true, parent);
  };

  toggleShowTools = () => this.setState({ showTools: !this.state.showTools });
  onSubmit = async (mail: SaveQuestion) => {
    this.setState({ loading: true });
    const success = await this.props.save(mail);

    if (success) {
      this.closeDialog();
      if (!mail.id && mail.parent) {
        this.openDialog(mail.parentQuestion);
      } else {
        const refresh = this.props.data.find((m) => m.id === mail.id);
        if (refresh) {
          this.props.onQuestionSelected(refresh);
        }
      }
    }
    this.setState({ loading: false });
    this.gridApi?.redrawRows();

    return success;
  };

  onRowClicked = ({ data }: RowClickedEvent) => {
    const mail: OrderQuestionSnapshotType = data;
    this.props.onQuestionSelected(mail);
  };

  onRowDoubleClicked = ({ data }: RowDoubleClickedEvent) => {
    const canOpen =
      this.props.canManage &&
      (data.canEditQuestion ||
        data.canRemoveQuestion ||
        data.canAddAnswer ||
        data.canAddImplementer ||
        data.canChangeCompleted);

    canOpen && this.openDialog(data);
  };

  openDialog = async (question: any, isNew: boolean = false, parent?: OrderQuestionSnapshotType) => {
    const { storre } = this.props;
    let orderGip = this.props.orderGip;
    if (this.props.orders) {
      if (this.props.storre.filtersOrderId) {
        const el = this.props.orders.orders.find((o) => o.id === this.props.storre.filtersOrderId);
        if (el && el.employer) {
          orderGip = el.employer.id;
        }
      }
    }
    if (!isNew) {
      this.setState({ loading: true });
      await this.props.storre.getById(question.id);
      this.setState({ loading: false, editable: mapQuestion(storre.question, orderGip), question: storre.question });
    } else {
      console.info(parent);
      this.setState({ editable: mapQuestion(question, orderGip, parent), question });
    }

    if (this.gridApi) {
      const node = this.gridApi.getRowNode(this.getRowNodeId(question));
      node && node.setSelected(true, true);
    }
  };

  closeDialog = () => this.setState({ editable: null, question: null });

  onSelectionChanged = (e: SelectionChangedEvent) => {
    const selection = e.api.getSelectedNodes().map((n) => n.data);
    this.setState({ selection });
  };

  getRowNodeId = (node: OrderQuestionSnapshotType) => node.id;

  onGridReady = async ({ api }: GridReadyEvent) => {
    const { sorter } = this.props;
    setTimeout(() => api.setSortModel(sorter.agGridSortModel));
    this.gridApi = api;
    this.gridApi.resetRowHeights();
    api.resetRowHeights();
  };

  batchPrint = async () => {
    this.setState({ loading: true });
    // const { selection } = this.state;
    // await this.props.printMails(selection.map((m) => m.id));
    await this.props.printMails([]);
    this.setState({ loading: false });
  };

  batchDownload = () => {
    const { selection } = this.state;
    if (selection.length) {
      this.props.downloadMails(selection.map((m) => m.id));
    }
  };
}

interface QuestionsListProps extends StoreContract {
  columns: (canManage: boolean, baseUrl: string) => ColDef[];
  rowBuffer: number;
  canManage: boolean;
  onQuestionSelected: (mail: OrderQuestionSnapshotType) => void;
  current: OrderQuestionSnapshotType | null;
  hideProject: boolean;
  isActiveTab: boolean;
  canAddQuestion?: boolean;
  setOrder?: () => void;
  storre: OrderQuestionStoreType;
  orderGip?: string | null;
  currentUserId: string;
}

interface QuestionsListState {
  selection: OrderQuestionSnapshotType[];
  editable: SaveQuestion | null;
  question: OrderQuestionSnapshotType | null;
  assigment: OrderQuestionSnapshotType[];
  colDefs: ColDef[];
  showTools: boolean;
  loading: boolean;
  orders: OrderDictionaryItemType[];
}
